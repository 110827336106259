/* Navbar.css */

/* Custom navbar styles */
.custom-navbar {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
}

.navbar-logo {
    height: 40px;
    margin-right: 10px;
}

.nav-link {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-nav .nav-item .nav-link {
    transition: color 0.3s ease;
}

.navbar-nav .nav-item .nav-link:hover {
    color: #0056b3;
}

.btn-outline-danger {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-outline-danger:hover {
    background-color: #dc3545;
    color: white;
}

/* Adjust navbar items for mobile */
@media (max-width: 768px) {
    .navbar-brand {
        flex-grow: 1;
        text-align: center;
    }
    .navbar-collapse {
        text-align: center;
    }
    .navbar-nav {
        flex-direction: column;
    }
    .navbar-nav .nav-item {
        margin: 0.5rem 0;
    }
}
