/* Make font size smaller for mobile devices */
@media (max-width: 768px) {
    .dashboard-title {
        font-size: 0.9rem;
    }
    .card-header {
        font-size: 0.8rem;
    }
    .card-body {
        font-size: 0.7rem;
    }
    .table th, .table td {
        padding: 0.1rem;
        font-size: 0.7rem;
    }
    .badge {
        font-size: 0.6rem;
    }
}

/* Right align text */
.text-right {
    text-align: right;
}
