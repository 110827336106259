/* Make font size smaller for mobile devices */
@media (max-width: 768px) {
    .dashboard-title {
        font-size: 0.9rem;
    }
    .card-header {
        font-size: 0.8rem;
    }
    .card-body {
        font-size: 0.7rem;
    }
    .table th, .table td {
        padding: 0.1rem;
        font-size: 0.7rem;
    }
    .badge {
        font-size: 0.6rem;
    }
    .btn-sm {
        font-size: 0.6rem;
        padding: 2px 4px;
        border: 1px solid #ddd; /* Light border */
    }
    .btn-group-sm .btn {
        flex: 1;
        margin: 2px 1px;
    }
    .navbar-nav .nav-link {
        font-size: 0.8rem;
        padding: 2px 5px;
    }
    .btn-outline-danger {
        font-size: 0.7rem;
        padding: 2px 5px;
    }
}

/* Right align text */
.text-right {
    text-align: right;
}
