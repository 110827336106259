/* Control.css */
.control-container {
    padding: 1rem;
}

.form-group {
    margin-bottom: 1rem;
}

.description-text {
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.confirm-dialog {
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-top: 1rem;
}

.dialog-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.response-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
}

@media (max-width: 768px) {
    .control-container {
        padding: 0.5rem;
    }
    .form-group label {
        font-size: 0.9rem;
    }
    .form-group select {
        font-size: 0.9rem;
    }
    .description-text {
        font-size: 0.8rem;
    }
    .btn {
        font-size: 0.8rem;
        padding: 0.4rem 0.6rem;
    }
    .confirm-dialog p {
        font-size: 0.8rem;
    }
    .dialog-buttons button {
        font-size: 0.8rem;
    }
    .response-message {
        font-size: 0.9rem;
    }
}
